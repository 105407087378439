enum StorageTypes {
  SkipFirstRuleModal = 'SkipFirstRuleModal',
}

let uid: string;
const currentVersion: string = process.env.VERSION || '';
// use function: make sure to get the latest version
const storageVersion = () => localStorage.getItem('version_name');
const storageDetail = () =>
  JSON.parse(localStorage.getItem('version_detail') || '{}');

// clear localStorage if not the same version
function resetStorage() {
  if (storageVersion() && storageVersion() !== currentVersion) {
    localStorage.removeItem('version_name');
    localStorage.removeItem('version_detail');
  }
}
function initStorage(userUid: string) {
  resetStorage(); // reset
  uid = userUid; // set uid
}
function setStorage(key: string, val: boolean | string) {
  if (!uid) {
    // eslint-disable-next-line no-console
    console.warn('Cannot find user uid, please call initStorage first');
    return;
  }
  if (!storageVersion()) localStorage.setItem('version_name', currentVersion);
  const detail = { ...storageDetail(), [`${key}_${uid}`]: val };
  localStorage.setItem('version_detail', JSON.stringify(detail));
}
function getStorage(key: string) {
  const value = storageDetail()[`${key}_${uid}`];
  return value || null;
}

export { StorageTypes, initStorage, setStorage, getStorage };
