import axios, { AxiosResponse } from 'axios';
import utils, { AnyJson } from '@/utils';

// store access_token
const token =
  utils.getParam('gop_access_token') || utils.getParam('access_token');
const partition =
  utils.getParam('partition') || localStorage.getItem('PARTITION');
if (token) localStorage.setItem('TOKEN', token);
if (partition) localStorage.setItem('PARTITION', partition);

const API = '/api';

export const instance = axios.create({
  baseURL: API,
  headers: {
    'Content-Type': 'application/json',
    'Utm-Source': 'plt',
  },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  withCredentials: true,
});

instance.interceptors.request.use(function (config) {
  const TOKEN = localStorage.getItem('TOKEN');
  const PARTITION = localStorage.getItem('PARTITION');
  config.headers = {
    ...config.headers,
    'Access-Token': TOKEN || '',
    Partition: PARTITION || 1,
  };
  return config;
});

// Add a response interceptor
/* eslint-disable @typescript-eslint/no-explicit-any */
instance.interceptors.response.use(
  (response: any) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return utils.camelizeKeys(response.data) as unknown as AxiosResponse;
  },
  (error: Error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

export default function <T>(method: string, url: string, data: AnyJson = null) {
  const requestType = method.toLowerCase();
  const normalizedParams = utils.snakifyKeys(data);

  switch (requestType) {
    case 'get':
      return instance.get(url, { params: normalizedParams }) as Promise<T>;
    case 'put':
      return instance.put(url, normalizedParams) as Promise<T>;
    case 'delete':
      return instance.delete(url, { params: normalizedParams }) as Promise<T>;
    case 'post':
      return instance.post(url, normalizedParams) as Promise<T>;
    default:
      throw new Error(
        `Unknown request method of ${method}! You might have a typo.`,
      );
  }
}
