import { useModal, useLoading } from '@/store/';

import {
  ProfileResponse,
  InvitationResopnse,
  FriendlistResponse,
  ApplicationResponse,
  DismissResponse,
  LeaveResponse,
  KickoutResponse,
  KickoutApplyResponse,
  JoinResponse,
  CreateResponse,
  ReplyResponse,
  CheckcodeResponse,
  ClaimResponse,
} from '@/services/responseType';

type ApiResult =
  | ProfileResponse
  | InvitationResopnse
  | FriendlistResponse
  | ApplicationResponse
  | DismissResponse
  | LeaveResponse
  | KickoutResponse
  | KickoutApplyResponse
  | JoinResponse
  | CreateResponse
  | ReplyResponse
  | CheckcodeResponse
  | ClaimResponse
  | {};

type Error = { error?: string };

export default async function useApi<T extends ApiResult & Error>(
  asyncFunc: () => Promise<T>,
) {
  const { updateLoadingState } = useLoading();
  const { handleMessageModal } = useModal();
  try {
    updateLoadingState(true);
    const response = await asyncFunc();
    if ('error' in response) {
      handleMessageModal(response.error || 'ERROR__SERVER_ERROR');
    } else {
      return response;
    }
  } catch (errorRes: any) {
    handleMessageModal('ERROR__SERVER_ERROR');
  } finally {
    updateLoadingState(false);
  }
  return null;
}
