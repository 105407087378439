import { createI18n, useI18n } from 'vue-i18n';
import messages from './node';

const langMap = JSON.parse(process.env.LANG_MAP || '');
const regionPostfix =
  process.env.REGION === 'sg' ? '' : `-${process.env.REGION}`;

const loadedLocales = {};

const fetchMessage = async (locale) => {
  try {
    const res = await fetch(
      `https://rosetta${regionPostfix}.garenanow.com/transify/${process.env.TRANSIFY_ID}?lang=${langMap[locale]}`,
    );
    if (res.status === 200) {
      return res.json();
    }
    throw Error(`Fetch Rosetta failed with status ${res.status}`);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Referring to local translation due to', e);
    return messages[locale];
  }
};

export const i18n = () => {
  const { locale, setLocaleMessage } = useI18n();

  const setLocale = (newLocale) => {
    locale.value = newLocale;
  };

  const loadLocale = async (newLocale) => {
    if (newLocale in loadedLocales) {
      return setLocale(newLocale);
    }
    const message = await fetchMessage(newLocale);
    setLocaleMessage(newLocale, message);
    return setLocale(newLocale);
  };

  return { ...useI18n(), loadLocale };
};

export default createI18n({
  legacy: false,
  locale: process.env.LOCALE,
  fallbackLocale: process.env.LOCALE,
  globalInjection: true,
  messages,
  warnHtmlMessage: false,
});
