import { defineStore } from 'pinia';
import { TypeGame } from './storeType';
import {
  ToastType,
  ModalType,
  ApplicationStatusType,
  PlayerActionType,
  KickoutReplayType,
  KickoutApplyStatusType,
} from './constant';
import { KickoutApplicationListItem } from '@/services/responseType';
import services from '@/services';
import { useTeam, useModal, useLoading } from './index';
import useApi from '@/useApi';
import { initStorage } from '@/useLocalStorage';

const state = (): TypeGame => ({
  profile: {
    showConsent: false,
    uniqueId: '',
    eventRemainingTime: 0,
    milestoneProgress: 0,
    teamNameLimit: 0,
    tutorialPicNum: 0,
    useDafaultTeamName: false,
    serialCode: '',
  },
  player: {
    avatar: '',
    name: '',
    inTeam: false,
    isTopup: false,
    isKickout: false,
    isLeader: false,
    newInvitation: false,
    newKickoutApplication: false,
  },
  team: {
    teamName: '',
    teamCode: '',
    newApplication: false,
    teamMembers: [],
    newMembers: [],
  },
  milestone: [],
  friendList: {
    friends: [],
    invitedList: [],
  },
  invitationList: [],
  applicationList: [],
  kickoutApplicationList: [],
  kickoutReply: {
    applicationId: 0,
    action: 0,
  },
});

const useGame = defineStore('game', {
  state,
  getters: {
    dismissalAvailable(state) {
      const memberCount = state.team.teamMembers.filter((ele) => !!ele);
      return state.player.isLeader && memberCount.length === 1;
    },
    leavingAvailable(state) {
      if (!state.player.isLeader) {
        return !state.player.isTopup;
      }
      return false;
    },
    canReceiveInvitation(state) {
      return (
        (state.player.isTopup && !state.player.inTeam) || !state.player.isTopup
      );
    },
    currentMemberCount(state) {
      return state.team.teamMembers.filter((ele) => ele !== null).length;
    },
  },
  actions: {
    async handleInvitation() {
      if (this.canReceiveInvitation && this.profile.uniqueId) {
        const response = await useApi(services.getInvitation);
        if (!response) return;

        const { invitationList } = response;

        this.invitationList = invitationList;

        const { openModal } = useModal();

        if (invitationList.length) {
          openModal(ModalType.TeamInvitationModal);

          this.player.newInvitation = false;
        }
      }
      this.handleNewState();
    },
    handleNewState() {
      const { openNewMemberModal, handleMessageModal } = useModal();

      if (this.team.newMembers.length) {
        openNewMemberModal(this.team.newMembers);
      }
      if (this.player.isKickout) {
        handleMessageModal('ERROR__HAS_BEEN_KICKED_OUT');
      }
    },
    async handleRefresh() {
      const { updateRefreshingState } = useLoading();
      updateRefreshingState(true);

      await this.getProfile();

      updateRefreshingState(false);
      this.handleNewState();
    },
    async getProfile() {
      const response = await useApi(services.getProfile);
      if (!response) return;

      const { player, team, milestone, ...res } = response;
      this.player = player;
      this.team = team;
      this.milestone = milestone;
      this.profile = res;

      initStorage(res.uniqueId);

      const { updateTeamState } = useTeam();
      updateTeamState();
    },

    async getFriendlist() {
      const response = await useApi(services.getFriendlist);
      if (!response) return;

      const { friendList } = response;
      this.friendList.friends = friendList;

      const { openModal, handleMessageModal } = useModal();
      if (friendList.length) {
        openModal(ModalType.SendInvitationModal);
      } else {
        handleMessageModal('ERROR__NO_FRIENDS');
      }
    },

    async getInvitation() {
      const response = await useApi(services.getInvitation);
      if (!response) return;

      const { invitationList } = response;

      this.invitationList = invitationList;

      const { openModal, handleMessageModal } = useModal();

      if (invitationList.length) {
        openModal(ModalType.TeamInvitationModal);

        this.player.newInvitation = false;
      } else {
        handleMessageModal('ERROR__NO_INVITATION');
      }
    },

    async getApplication() {
      const response = await useApi(services.getApplication);
      if (!response) return;

      const { applicationList } = response;

      this.applicationList = applicationList.map((ele) => ({
        ...ele,
        status: ApplicationStatusType.Available,
      }));

      const { openModal, handleMessageModal } = useModal();

      if (applicationList.length) {
        openModal(ModalType.TeamApplicationModal);

        this.team.newApplication = false;
      } else {
        handleMessageModal('ERROR__NO_APPLICATION');
      }
    },

    async getKickoutApplication() {
      const response = await useApi(services.getKickoutApplication);
      if (!response) return;

      const { applicationList } = response;
      this.kickoutApplicationList = applicationList;

      const { openModal } = useModal();
      openModal(ModalType.KickoutApplicationModal);
    },

    async postDismiss() {
      const response = await useApi(services.postDismiss);
      if (!response) return;

      const { player, team, milestone, ...res } = response;
      this.player = { ...this.player, ...player };
      this.team = { ...this.team, ...team };
      this.milestone = milestone;
      this.profile = { ...this.profile, ...res };

      const { updateTeamState } = useTeam();
      updateTeamState();

      const { closeModal, handleToast } = useModal();
      closeModal();
      handleToast(ToastType.DismissSuccess);
    },

    async postLeave() {
      const response = await useApi(services.postLeave);
      if (!response) return;

      const { player, team, milestone, ...res } = response;
      this.player = { ...this.player, ...player };
      this.team = { ...this.team, ...team };
      this.milestone = milestone;
      this.profile = { ...this.profile, ...res };

      const { updateTeamState } = useTeam();
      updateTeamState();

      const { closeModal, handleToast } = useModal();
      closeModal();
      handleToast(ToastType.LeaveSuccess);
    },

    async postKickoutApply(params: { memberId: number }) {
      const response = await useApi(() => services.postKickoutApply(params));
      if (!response) return;

      const { closeModal, handleToast } = useModal();
      closeModal();

      // show toast
      handleToast(ToastType.KickoutApplySuccess);
    },

    kickoutReplyConfirm(params: { applicationId: number; action: number }) {
      // set params
      this.kickoutReply.applicationId = params.applicationId;
      this.kickoutReply.action = params.action;

      const { handlePlayerActionModal } = useModal();
      handlePlayerActionModal(
        params.action === KickoutReplayType.Keep
          ? PlayerActionType.KickOutReplyKeep
          : PlayerActionType.KickOutReplyKickOut,
      );
    },

    async postKickoutReply(params: { applicationId: number; action: number }) {
      const response = await useApi(() => services.postKickoutReply(params));
      if (!response) return;

      const { closeModal, handleToast } = useModal();
      closeModal();

      const { applicationList } = response;
      this.kickoutApplicationList = applicationList;

      // show toast
      handleToast(
        params.action === KickoutReplayType.Keep
          ? ToastType.KeepSuccess
          : ToastType.KickoutSuccess,
      );
    },

    autoKickout(applicationId: number) {
      const findIndex = this.kickoutApplicationList.findIndex((item) => {
        return item.applicationId === applicationId;
      });
      this.kickoutApplicationList[findIndex].status =
        KickoutApplyStatusType.KickOut;
    },

    async postKickout(params: { memberId: number }) {
      const response = await useApi(() => services.postKickout(params));
      if (!response) return;

      const { team, milestone, ...res } = response;
      this.team = { ...this.team, ...team };
      this.milestone = milestone;
      this.profile = { ...this.profile, ...res };

      const { updateTeamState } = useTeam();
      updateTeamState();

      const { closeModal } = useModal();
      closeModal();
    },

    async postInvite(params: { tencentId: string; partition: number }) {
      const response = await useApi(() => services.postInvite(params));
      if (!response) return;

      const { handleToast } = useModal();
      handleToast(ToastType.InviteSuccess);
      this.friendList.invitedList.push(params.tencentId + params.partition);
    },

    async postJoin(params: { invitationId: number; action: number }) {
      const response = await useApi(() => services.postJoin(params));
      if (!response) return;

      const { player, team, milestone, ...res } = response;
      this.player = { ...this.player, ...player };
      this.team = { ...this.team, ...team };
      this.milestone = milestone;
      this.profile = { ...this.profile, ...res };

      const { updateTeamState } = useTeam();
      const { closeModal, handleToast, clearNewMemberModal } = useModal();
      clearNewMemberModal();
      closeModal();
      updateTeamState();
      handleToast(ToastType.JoinSuccess);
    },

    async postCreate(params: { teamName: string }) {
      const response = await useApi(() => services.postCreate(params));

      if (!response) {
        throw Error();
      }

      const { player, team, milestone, ...res } = response;
      this.player = { ...this.player, ...player };
      this.team = { ...this.team, ...team };
      this.milestone = milestone;
      this.profile = { ...this.profile, ...res };

      const { updateTeamState } = useTeam();
      const { closeModal, handleToast } = useModal();
      updateTeamState();
      closeModal();
      handleToast(ToastType.CreateSuccess);
    },

    async postReply(params: { applicationId: number; action: number }) {
      const response = await useApi(() => services.postReply(params));

      const targetIdx = this.applicationList.findIndex(
        (ele) => ele.applicationId === params.applicationId,
      );

      const { handleToast } = useModal();

      if (!response) {
        this.applicationList[targetIdx].status =
          ApplicationStatusType.Unavailable;
        return;
      } else {
        if (params.action === 1) {
          this.applicationList[targetIdx].status =
            ApplicationStatusType.Accepted;
          handleToast(ToastType.AcceptSuccess);
        } else {
          this.applicationList[targetIdx].status =
            ApplicationStatusType.Declined;
          handleToast(ToastType.DeclineSuccess);
        }
      }

      const { team, milestone, ...res } = response;

      this.team = { ...this.team, ...team };
      this.milestone = milestone;
      this.profile = { ...this.profile, ...res };
    },

    async getCheckcode(params: { teamCode: string }) {
      const response = await useApi(() => services.getCheckcode(params));
      if (!response) return;

      const { teamName } = response;

      const { handleApplyTeamName } = useModal();

      handleApplyTeamName(teamName);
    },

    async postApply(params: { teamCode: string }) {
      const response = await useApi(() => services.postApply(params));
      if (!response) return;

      const { handleToast, closeModal } = useModal();

      closeModal();
      handleToast(ToastType.ApplySuccess);
    },

    async postClaim(params: { milestoneId: number }) {
      const response = await useApi(() => services.postClaim(params));
      if (!response) return;

      const { reward, team, milestone, ...res } = response;
      this.team = { ...this.team, ...team };
      this.milestone = milestone;
      this.profile = { ...this.profile, ...res };

      const { openModal, handleReward } = useModal();

      openModal(ModalType.RewardModal);
      handleReward(reward);
    },

    async postConsent(params: { isConsented: boolean }) {
      this.profile.showConsent = false;
      await services.postConsent(params);
    },

    setKickoutApplicationList(list: KickoutApplicationListItem[]) {
      this.kickoutApplicationList = list;
    },
  },
});

export default useGame;
