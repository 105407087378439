function getParam(paramName: string, url: string = window.location.href) {
  const name = paramName.replace(/[[]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function camelizeStr(str: string): string {
  return str.replace(/[_.-](\w|$)/g, (_, x) => x.toUpperCase());
}

function snakifyStr(str: string): string {
  return str.replace(/(?:^|\.?)([A-Z])/g, (_, x) => `_${x.toLowerCase()}`);
}

type ConvertFunc = (k: string) => string;
export type AnyJson =
  | boolean
  | number
  | string
  | null
  | undefined
  | JsonArray
  | JsonMap;
interface JsonMap {
  [key: string]: AnyJson;
}
type JsonArray = Array<AnyJson>;

function convertCase(convertFunc: ConvertFunc) {
  function converter(thing: AnyJson): AnyJson {
    if (Array.isArray(thing)) {
      return thing.map((i) => converter(i));
    }
    if (thing && typeof thing === 'object') {
      const newObj: JsonMap = {};
      Object.keys(thing).forEach((k: string) => {
        newObj[convertFunc(k)] = converter(thing[k]);
      });
      return newObj;
    }
    return thing;
  }
  return converter;
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function replaceDefalutAvatar(e: { target: HTMLImageElement }) {
  e.target.src =
    'https://dlgarenanow-a.akamaihd.net/mgames/kgtw/events/202012SkinReview/avatar/head.jpg';
}

function formatTime(time: number) {
  const hr = Math.floor(time / 3600);
  const min = Math.floor((time - hr * 3600) / 60);
  const sec = Math.floor(time - (hr * 3600 + min * 60));

  return `${hr.toString().padStart(2, '0')}:${min
    .toString()
    .padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
}

export default {
  getParam,
  camelizeStr,
  snakifyStr,
  delay,
  camelizeKeys: convertCase(camelizeStr),
  snakifyKeys: convertCase(snakifyStr),
  replaceDefalutAvatar,
  formatTime,
};
