import api from './api';
import {
  ProfileResponse,
  InvitationResopnse,
  FriendlistResponse,
  ApplicationResponse,
  KickoutApplicationResponse,
  DismissResponse,
  LeaveResponse,
  KickoutResponse,
  KickoutApplyResponse,
  KickoutReplyResponse,
  JoinResponse,
  CreateResponse,
  ReplyResponse,
  CheckcodeResponse,
  ClaimResponse,
} from './responseType';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const mock = require('./mock').default;
  mock();
}

export default {
  getProfile: () => api<ProfileResponse>('get', '/profile'),
  getInvitation: () => api<InvitationResopnse>('get', '/invitation'),
  getFriendlist: () => api<FriendlistResponse>('get', '/friendlist'),
  getApplication: () => api<ApplicationResponse>('get', '/application'),
  getKickoutApplication: () =>
    api<KickoutApplicationResponse>('get', '/kickout-application'),
  postDismiss: () => api<DismissResponse>('post', '/dismiss'),
  postLeave: () => api<LeaveResponse>('post', '/leave'),
  postKickout: (params: { memberId: number }) =>
    api<KickoutResponse>('post', '/kickout', params),
  postKickoutApply: (params: { memberId: number }) =>
    api<KickoutApplyResponse>('post', '/kickout-apply', params),
  postKickoutReply: (params: { applicationId: number; action: number }) =>
    api<KickoutReplyResponse>('post', '/kickout-reply', params),
  postJoin: (params: { invitationId: number; action: number }) =>
    api<JoinResponse>('post', '/join', params),
  postCreate: (params: { teamName: string }) =>
    api<CreateResponse>('post', '/create', params),
  postInvite: (params: { tencentId: string; partition: number }) =>
    api<{}>('post', '/invite', params),
  postReply: (params: { applicationId: number; action: number }) =>
    api<ReplyResponse>('post', '/reply', params),
  getCheckcode: (params: { teamCode: string }) =>
    api<CheckcodeResponse>('get', '/checkcode', params),
  postApply: (params: { teamCode: string }) =>
    api<{}>('post', '/apply', params),
  postClaim: (params: { milestoneId: number }) =>
    api<ClaimResponse>('post', '/claim', params),
  postConsent: (params: { isConsented: boolean }) =>
    api('post', '/consent', params),
};
