import { defineStore } from 'pinia';
import { ModalType, ToastType, PlayerActionType } from './constant';
import { useGame } from './index';

interface ModalState {
  modals: ModalType[];
  newMember: { avatar: string; name: string }[];
  detail: TypeDetail;
  toast: ToastType | null;
}

type TypeDetail = {
  message: string;
  teamName: string;
  reward: {
    name: string;
    image: string;
  };
  playerActionType: string;
  playerInfo: {
    name: string;
    memberId: number;
  } | null;
};

const state = (): ModalState => ({
  modals: [],
  newMember: [],
  detail: {
    message: '',
    teamName: '',
    reward: { name: '', image: '' },
    playerActionType: '',
    playerInfo: null,
  },
  toast: null,
});

const useModal = defineStore('modal', {
  state,
  actions: {
    openModal(modal: ModalType) {
      this.modals.push(modal);
    },
    closeModal() {
      this.handleKickoutApplicationModalClose();
      this.handleMsgModalErrorClose();

      this.modals.pop();
    },
    clearModal() {
      this.modals = [];
    },
    async handleKickoutApplicationModalClose() {
      // auto refresh profile
      if (
        this.modals[this.modals.length - 1] ===
        ModalType.KickoutApplicationModal
      ) {
        const { getProfile } = useGame();
        await getProfile();
      }
    },
    handleMsgModalErrorClose() {
      if (
        this.modals[this.modals.length - 1] === ModalType.MessageModal &&
        this.detail.message === 'ERROR__APPLICANT_IS_DUPLICATE'
      ) {
        this.clearModal();
      }
    },
    handlePlayerActionModal(
      type: PlayerActionType,
      info: {
        name: string;
        memberId: number;
      } | null = null,
    ) {
      this.modals.push(ModalType.PlayerActionModal);
      this.detail.playerActionType = type;
      this.detail.playerInfo = info;
    },
    openNewMemberModal(newMember: { avatar: string; name: string }[]) {
      this.newMember = newMember;
    },
    closeNewMemberModal() {
      this.newMember.pop();
    },
    clearNewMemberModal() {
      this.newMember = [];
    },
    handleMessageModal(msg: string) {
      this.modals.push(ModalType.MessageModal);
      this.detail.message = msg;
    },
    handleApplyTeamName(teamName: string) {
      this.detail.teamName = teamName;
    },
    handleReward(reward: { name: string; image: string }) {
      this.detail.reward = reward;
    },

    handleToast(toast: ToastType) {
      this.toast = toast;
      setTimeout(() => {
        this.toast = null;
      }, 1500);
    },
  },
});

export default useModal;
