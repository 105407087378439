import { createApp } from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import i18n from '@/i18n';
import { createPinia } from 'pinia';
import '@/preloader';
import '@/styles/global.scss';
import setupLayout from '@/layout';
setupLayout();

const App = require(`./versions/${process.env.VERSION_NAME}/App.vue`).default;

const pinia = createPinia();
const app = createApp(App);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://8af6b314dbfd49aca0bee826a1e0dd58@sentry.twrevenue.garenanow.com/208',
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    integrations: [new Integrations.Vue({ app, attachProps: true })],
  });
}

app.use(i18n).use(pinia).mount('#app');
