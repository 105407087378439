import { defineStore } from 'pinia';

interface LoadingAction {
  isLoading: boolean;
  isRefreshing: boolean;
}

const LoadingState: LoadingAction = {
  isLoading: false,
  isRefreshing: false,
};

export const useLoading = defineStore('loading', {
  state: () => LoadingState,
  actions: {
    updateLoadingState(state: boolean) {
      this.isLoading = state;
    },
    updateRefreshingState(state: boolean) {
      this.isRefreshing = state;
    },
  },
});

export default useLoading;
