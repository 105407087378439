<template>
  <div class="loading">
    <svg
      id="L9"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enable-background="new 0 0 0 0"
      xml:space="preserve"
    >
      <path
        fill="#eae8d4"
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
      ></path>
    </svg>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.loading {
  @include fullcover;
  @include flexCenter(row);
  background-color: #00000044;
  svg {
    animation: spin 0.8s linear infinite;
    width: 60px;
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
