export enum ModalType {
  MessageModal = 'MessageModal',
  TeamInvitationModal = 'TeamInvitationModal',
  CreateTeamModal = 'CreateTeamModal',
  SendInvitationModal = 'SendInvitationModal',
  InvitationCodeModal = 'InvitationCodeModal',
  TeamApplicationModal = 'TeamApplicationModal',
  InputCodeModal = 'InputCodeModal',
  RewardModal = 'RewardModal',
  RuleModal = 'RuleModal',
  PlayerActionModal = 'PlayerActionModal',
  CreateDefaultTeamModal = 'CreateDefaultTeamModal',
  KickoutApplicationModal = 'KickoutApplicationModal',
}

export enum PlayerActionType {
  Dismiss = 'Dismiss',
  Leave = 'Leave',
  KickOut = 'KickOut',
  KickOutApply = 'KickOutApply',
  KickOutReplyKickOut = 'KickOutReplyKickOut',
  KickOutReplyKeep = 'KickOutReplyKeep',
}

export enum ToastType {
  CreateSuccess = 'CreateSuccess',
  InviteSuccess = 'InviteSuccess',
  JoinSuccess = 'JoinSuccess',
  CopySuccess = 'CopySuccess',
  AcceptSuccess = 'AcceptSuccess',
  DeclineSuccess = 'DeclineSuccess',
  ApplySuccess = 'ApplySuccess',
  DismissSuccess = 'DismissSuccess',
  LeaveSuccess = 'LeaveSuccess',
  KickoutSuccess = 'KickoutSuccess',
  KeepSuccess = 'KeepSuccess',
  KickoutApplySuccess = 'KickoutApplySuccess',
}

export enum ActionButtonType {
  TeamInvitation = 'TeamInvitation', // friend invitation
  InputCode = 'InputCode',
  InvitationCode = 'InvitationCode', // team code
  SendInvitation = 'SendInvitation',
  TeamApplication = 'TeamApplication',
  KickoutApplication = 'KickoutApplication',
}

export enum TeamStatusType {
  Single = 'Single', // No team
  LeaderInTeam = 'LeaderInTeam', // player is the leader in team , but team isn't full
  MemberInTeam = 'MemberInTeam', // player is a member in team , but team isn't full
  FullTeam = 'FullTeam', // Team is full
  Complete = 'Complete', //  Team is full, and all members topped up
}

export enum ApplicationStatusType {
  Accepted = 'Accepted',
  Declined = 'Declined',
  Available = 'Available',
  Unavailable = 'Unavailable',
}

export enum MilestoneRewardStatusType {
  NotReach = 0,
  Obtainable = 1,
  Claimed = 2,
}

export enum KickoutApplyStatusType {
  UnderReview = 0,
  Keep = 1,
  ToppedUp = 2,
  KickOut = 3,
}

export enum KickoutReplayType {
  Kickout = 1,
  Keep = 2,
}
