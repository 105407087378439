/*
 * image preloader
 */

/* eslint-disable global-require */
const images: string[] = [
  // `${process.env.CDN}/background.jpg`,
];

images.forEach((image) => {
  new Image().src = image;
});
