import { defineStore } from 'pinia';
import { ActionButtonType, TeamStatusType } from './constant';
import { useGame } from './index';

interface TypeTeam {
  teamStatus: TeamStatusType | '';
  actionButtons: ActionButtonType[];
}

const state = (): TypeTeam => ({
  teamStatus: '',
  actionButtons: [],
});

const useTeam = defineStore('team', {
  state,
  getters: {
    isTeamFull() {
      const { team } = useGame();
      return team.teamMembers.filter((ele) => !ele).length <= 0;
    },
    isAllToppedUp() {
      const { team } = useGame();
      return team.teamMembers.every((ele) => ele?.isTopup);
    },
  },
  actions: {
    updateTeamState() {
      this.$reset();

      const { player } = useGame();

      if (!player.inTeam) {
        this.teamStatus = TeamStatusType.Single;
        return;
      }

      if (player.isLeader && !this.isTeamFull) {
        this.teamStatus = TeamStatusType.LeaderInTeam;
      } else if (!player.isLeader && !this.isTeamFull) {
        this.teamStatus = TeamStatusType.MemberInTeam;
      }

      if (this.isAllToppedUp) {
        this.teamStatus = TeamStatusType.Complete;
      } else if (this.isTeamFull) {
        this.teamStatus = TeamStatusType.FullTeam;
      }
    },
  },
});

export default useTeam;
