import { defineStore } from 'pinia';

interface TooltipState {
  info: {
    content: string;
    top: number;
    left: number;
  };
}

const state = (): TooltipState => ({
  info: {
    content: '',
    top: 0,
    left: 0,
  },
});

const useTooltip = defineStore('tooltip', {
  state,
  actions: {
    updateTooltip(info: { content: string; top: number; left: number }) {
      this.info = info;
    },
    resetTooltip() {
      this.$reset();
    },
  },
});

export default useTooltip;
